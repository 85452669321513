import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { toast } from "react-hot-toast";
import { events } from "fetch-event-stream";
import { PUBLIC_SUPABASE_ANON_KEY } from "~/utils/constants";

export type Result = {
  slug: string;
  differentiator: string;
  name: string;
  images: string[];
  instagram_followers: number | null;
  location_place_id: string;
  location_label: string;
  location_geography: string;
  confidence: "high" | "medium" | "low";
};

type ResultDifferentiator = {
  slug: string;
  differentiator: string;
};

type ClusterData = {
  name: string;
  query: string;
};

type ClusterImage = {
  name: string;
  image: string;
};

export type Cluster = ClusterData & ClusterImage;

type Data =
  | {
      type: "cluster";
      data: ClusterData;
    }
  | {
      type: "cluster_image";
      data: ClusterImage;
    }
  | {
      type: "result";
      data: Result;
    }
  | {
      type: "result_differentiator";
      data: ResultDifferentiator;
    }
  | {
      type: "finished";
      data: boolean;
    }
  | {
      type: "not_found";
      data: {
        message: string;
        scenario: number;
      };
    };

type SearchContextType = {
  loading: boolean;
  message: string;
  clusters: Cluster[];
  results: Result[];
  finished: boolean;
  data: Data[];
  loadMore: () => void;
  notFoundMessage: string | undefined;
  query: string | null;
  lat: string | null;
  lng: string | null;
  label: string | null;
  setQuery: (query: string | null) => void;
  setLat: (lat: string | null) => void;
  setLng: (lng: string | null) => void;
  setLabel: (label: string | null) => void;
};

const SearchContext = createContext<SearchContextType>({
  loading: false,
  message: "",
  clusters: [],
  results: [],
  finished: false,
  data: [],
  loadMore: () => {},
  notFoundMessage: undefined,
  query: null,
  lat: null,
  lng: null,
  label: null,
  setQuery: () => {},
  setLat: () => {},
  setLng: () => {},
  setLabel: () => {},
});

export function SearchProvider({ children }: { children: React.ReactNode }) {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<Data[]>([]);
  const [message, setMessage] = useState("");
  const abortController = useRef<AbortController | null>(null);

  const [query, setQuery] = useState<string | null>(null);
  const [lat, setLat] = useState<string | null>(null);
  const [lng, setLng] = useState<string | null>(null);
  const [label, setLabel] = useState<string | null>(null);

  const search = useCallback(
    async (query: string, skip: number, location: UserLocation) => {
      try {
        if (query === "" || !location) {
          return;
        }

        setLoading(true);

        if (abortController.current) {
          abortController.current.abort("New search started");
        }

        if (skip === 0) {
          setData([]);
          setMessage("");
        }

        abortController.current = new AbortController();

        const response = await fetch(
          `https://gampynikfsrnysutgahc.supabase.co/functions/v1/search`,
          {
            method: "POST",
            signal: abortController.current.signal,
            headers: {
              Authorization: `Bearer ${PUBLIC_SUPABASE_ANON_KEY}`,
            },
            body: JSON.stringify({
              query,
              skip,
              location: location,
            }),
          }
        );

        const stream = events(response, abortController.current.signal);

        for await (const event of stream) {
          if (!event.data) {
            continue;
          }

          const message = JSON.parse(event.data) as
            | {
                type: "variations_delta" | "message_delta";
                text: string;
              }
            | {
                type: "data";
                data: Data[];
              };

          if (message.type === "data") {
            setData((prev) => [...prev, ...message.data]);
          }

          if (message.type === "message_delta") {
            setMessage((prev) => prev + message.text);
          }
        }

        setLoading(false);
      } catch (error) {
        console.error(error);
        if (
          error instanceof Error &&
          (error.name === "AbortError" || error.name === "TimeoutError")
        ) {
          console.log("\n\nAbortError: The run was aborted.");
        } else {
          toast.error("Unable to search at the moment. Please retry again.");
        }
      }
    },
    []
  );

  const loadMore = useCallback(() => {
    if (!query || !lat || !lng || !label) return;

    const resultsCount = data.filter((d) => d.type === "result").length;

    search(query, resultsCount, {
      lat: Number(lat),
      lng: Number(lng),
      label: decodeURIComponent(label),
    });
  }, [query, lat, lng, label, search, data]);

  useEffect(() => {
    if (!query || !lat || !lng || !label) return;

    search(query, 0, {
      lat: Number(lat),
      lng: Number(lng),
      label: decodeURIComponent(label),
    });
  }, [query, lat, lng, label, search]);

  const clusters = useMemo(() => {
    return data
      .filter((d) => d.type === "cluster")
      .map((d) => {
        const clusterImage = data.find(
          (item) =>
            item.type === "cluster_image" && item.data.name === d.data.name
        )?.data as ClusterImage | undefined;

        return {
          ...d.data,
          image: clusterImage?.image || null,
        } as Cluster;
      });
  }, [data]);

  const results = useMemo(() => {
    return data
      .filter(
        (d) =>
          d.type === "result" &&
          d.data.confidence === "high" &&
          d.data.images.length > 0
      )
      .map((d) => {
        const result = d.data as Result;

        const differentiator = data.find(
          (item) =>
            item.type === "result_differentiator" &&
            item.data.slug === result.slug
        )?.data as ResultDifferentiator | undefined;

        if (differentiator) {
          result.differentiator = differentiator.differentiator;
        }

        return result;
      });
  }, [data]);

  const finished = useMemo(() => {
    return data.some((d) => d.type === "finished");
  }, [data]);

  const notFoundMessage = useMemo(() => {
    return data.find((d) => d.type === "not_found")?.data.message;
  }, [data]);

  return (
    <SearchContext.Provider
      value={{
        loading,
        message,
        clusters,
        results,
        finished,
        data,
        loadMore,
        notFoundMessage,
        query,
        lat,
        lng,
        label,
        setQuery,
        setLat,
        setLng,
        setLabel,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}

export const useSearch = () => useContext(SearchContext);
